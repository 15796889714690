import { useState, useEffect } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

import axiosInstance from 'api/axiosInstance';
import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import VuiInput from 'components/base/VuiInput';
import VuiButton from 'components/base/VuiButton';
import GradientBorder from 'components/ui/GradientBorder';
import CoverLayout from 'components/layout/CoverLayout';
import { useAuth } from 'context/AuthContext';

import radialGradient from 'assets/theme/functions/radialGradient';
import palette from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';


import bgSignIn from 'assets/images/signUpImage.png';

function SignUp() {
  const { getProfile, setIsAuthenticated } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  // Extract token from URL query parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, [location.search]);

  const signup = async (userData) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/auth/signup', userData);
      if (response.data.access_token) {
        toast.success('Signup successful!');
        setIsAuthenticated(true);
        localStorage.setItem('token', response.data.access_token);
        getProfile();
        history.push('/profile');
      } else {
        setIsRequestSent(true);
      }
    } catch (error) {
      if (error.response?.data?.status === 'self-signup/unverified') {
        toast.error(
          'Account is already created before with that email but not verified.'
        );
        setIsRequestSent(true);
      } else {
        toast.error(error?.response?.data?.message || 'Signup failed');
      }
    } finally {
      setLoading(false);
    }
  };

  const resendVerificationEmail = async () => {
    setLoading(true);
    setIsResendDisabled(true);
    try {
      const response = await axiosInstance.post(
        '/auth/resend-verification-email',
        { email }
      );
    } catch (error) {
      toast.error(
        error?.response?.data?.message[0] || 'Resend verification email failed'
      );
    } finally {
      setLoading(false);
      setTimeout(() => {
        setIsResendDisabled(false);
      }, 120000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = token
      ? { name, password, token }
      : { name, email, password };
    await signup(payload);
  };

  return (
    <>
      {isRequestSent ? (
        <CoverLayout
          title="Welcome!"
          color="white"
          description="You are almost there for signup."
          image={bgSignIn}
          premotto="Podio"
          motto="Podio Admin DASHBOARD"
        >
          <VuiBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={2.5}
          >
            <VuiTypography
              textAlign="center"
              mx="auto"
              sx={({ typography: { size }, functions: { pxToRem } }) => ({
                fontWeight: 'regular',
                fontSize: size.sm,
              })}
              color="white"
            >
              We already sent an email to <br />
              <b>{email}</b>
            </VuiTypography>
            <VuiTypography
              textAlign="center"
              mx="auto"
              sx={({ typography: { size }, functions: { pxToRem } }) => ({
                fontWeight: 'regular',
                fontSize: size.sm,
              })}
              color="white"
            >
              Just click on the link in that email to complete your signup. If
              you don&#39;t see it, you may need to <b>check your spam</b>{' '}
              folder.
            </VuiTypography>
            <VuiTypography
              textAlign="center"
              mx="auto"
              sx={({ typography: { size }, functions: { pxToRem } }) => ({
                fontWeight: 'regular',
                fontSize: size.sm,
              })}
              color="white"
            >
              Still can&#39;t find the email? No problem.
            </VuiTypography>
            <VuiButton
              color="info"
              fullWidth
              type="submit"
              disabled={loading || isResendDisabled}
              onClick={resendVerificationEmail}
            >
              {loading ? 'Resending...' : 'Resend Verification Email'}
            </VuiButton>
          </VuiBox>
        </CoverLayout>
      ) : (
        <CoverLayout
          title="Welcome!"
          color="white"
          description="Use these awesome forms to create a new account in your project for free."
          image={bgSignIn}
          premotto="Podio"
          motto="Podio Admin DASHBOARD"
        >
          <VuiBox
            component="form"
            role="form"
            onSubmit={handleSubmit}
            borderRadius="inherit"
          >
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography
                  component="label"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Name
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                borderRadius={borders.borderRadius.lg}
                padding="1px"
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  placeholder="Your full name..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </GradientBorder>
            </VuiBox>

            {/* Email Field - Only show if no token is present */}
            {!token && (
              <VuiBox mb={2}>
                <VuiBox mb={1} ml={0.5}>
                  <VuiTypography
                    component="label"
                    variant="button"
                    color="white"
                    fontWeight="medium"
                  >
                    Email
                  </VuiTypography>
                </VuiBox>
                <GradientBorder
                  minWidth="100%"
                  borderRadius={borders.borderRadius.lg}
                  padding="1px"
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <VuiInput
                    type="email"
                    placeholder="Your email..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </GradientBorder>
              </VuiBox>
            )}
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography
                  component="label"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Password
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                borderRadius={borders.borderRadius.lg}
                padding="1px"
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  type="password"
                  placeholder="Your password..."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </GradientBorder>
            </VuiBox>
            <VuiBox mt={4} mb={1}>
              <VuiButton
                color="info"
                fullWidth
                type="submit"
                disabled={loading}
              >
                {loading ? 'Signing up...' : 'SIGN UP'}
              </VuiButton>
            </VuiBox>
            <VuiBox mt={3} textAlign="center">
              <VuiTypography variant="button" color="text" fontWeight="regular">
                Already have an account?{' '}
                <VuiTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Sign in
                </VuiTypography>
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </CoverLayout>
      )}
    </>
  );
}

export default SignUp;
